import React, {
   useState,
   useEffect,
   useContext,
   Fragment,
   useRef,
} from "react";
import { Form, Row, Col } from "react-bootstrap";
//import PdfViewer from 'components/pdf/pdfViewer';
import StatusBar from "@components/StatusBar";
import { IcoUploadFile, CloudArrowDown, IcoCanc, Eye, EyeSlash } from "@icons";
import { Spinner } from "react-bootstrap";
import { LevelContext } from "components/contexts/LevelContext";
import {
   getOeDocuments,
   uploadDocument,
   getDocuments,
   downloadDocument,
   deleteDocument,
   //getBaseURL,
   documentPreview,
   uploadDocumentByDocumentId
} from "@lib/api";
import OEIframePreview from "components/oeiframepreview/OEIframePreview";
import { translate } from 'components/translation/translation';
import { determineMimeType } from "utils/determineMimeType";

const UploadDocument = ({
   entity,
   entityId,
   projectId,
   filterBySection,
   groupedBySection = false,
   disableDelete = false,
   disableEdit = false,
   externalBorder = false,
   enableUpload = false,
   isValidated = false,
   displayName = false,
   title = "Documenti",
}) => {
   const { userRole } = useContext(LevelContext);
   const [isPending, setIsPending] = useState(false);
   const [oeDocuments, setOeDocuments] = useState([]);
   const [sections, setSections] = useState([]);
   const [counter, setCounter] = useState({});
   const [uploadedDocuments, setUploadedDocuments] = useState([]);
   const [sbShow, setSbShow] = useState(<></>);
   const [previewMode, setPreviewMode] = useState(false);
   //const [previewUrl, setPreviewUrl] = useState('');
   const [previewBlob, setPreviewBlob] = useState(null);
   const [previewMimeType, setPreviewMimeType] = useState(null);
   const [selectedDocumentId, setSelectedDocumentId] = useState(null);
   const fileInputRef = useRef([]);
   //console.log('oeDocuments', oeDocuments);
   //console.log('filterBySection', filterBySection);

   useEffect(() => {
      getOeDocuments(entity, entity === 'timesheets' ? projectId : '').then((oeDocuments) => {
         if (oeDocuments.success === true) {
            oeDocuments = oeDocuments.data.filter(doc => !doc.deleted);

            if (filterBySection) {
               setOeDocuments(
                  oeDocuments
                     .filter((oeDoc) => {
                        return filterBySection.some((filter) => {
                           return oeDoc.section === filter;
                        });
                     })
                     .sort((a, b) => a.position - b.position)
               );
            } else {
               if (groupedBySection) {
                  let tempSections = oeDocuments.sort((a, b) => a.sectionPosition ? a.sectionPosition - b.sectionPosition : a.section - b.section)
                     .map((el) => el.section);
                  tempSections = tempSections.filter((item, index) => tempSections.indexOf(item) === index);

                  setSections(tempSections);
               }

               // Check if the data is an object or an array
               if (Array.isArray(oeDocuments)) {
                  setOeDocuments(oeDocuments.sort((a, b) => a.position - b.position));
               } else {
                  // Convert the object into an array
                  const oeDocumentsArray = Object.values(oeDocuments);
                  setOeDocuments(oeDocumentsArray.sort((a, b) => a.position - b.position));
               }
            }
         }
      });
   }, [entity, filterBySection, groupedBySection, projectId]);

   useEffect(() => {
      if (entityId) {
         getUploadedDocuments();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [entityId]);

   const getUploadedDocuments = () => {
      getDocuments(entity, entityId).then((documents) => {
         if (documents.success === true) {
            const counter = new Map();

            documents.data.forEach(({ documentCode, missing }) => {
               if (missing !== undefined && !missing) {
                  counter.set(documentCode, (counter.get(documentCode) || 0) + 1);
               }
            });

            const counterObject = Object.fromEntries(counter);
            setCounter(counterObject);
            setUploadedDocuments(documents.data);
         }
      });
   };

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   const handleUploadDocument = async (e, documentId) => {
      setSbShow(<></>);
      
      const file = e.target.files[0]; // Prende solo il primo file
      
      setIsPending(true);

      if (!file || !documentId) {
         setIsPending(false);
         return;
      }

      e.target.value = null;
      
      try {
         // Controllo della dimensione del file
         if (file && file.size > 200000000) {
            handleStatusBar(
               "error",
               "File troppo grande (massimo consentito: 200MB)",
               true
            );
            setIsPending(false);
            return false;
         }

         // UPLOAD del singolo file
         const result = await uploadDocumentByDocumentId(documentId, file);

         if (result) {
            handleStatusBar("success", "File caricato con successo", true);
         } else {
            handleStatusBar("error", "Errore nel caricamento del file", true);
         }

         getUploadedDocuments();
      } catch (error) {
         console.error(error);
         handleStatusBar("error", "Si è verificato un errore", true);
      } finally {
         setSelectedDocumentId(null);
      }

      setIsPending(false);
   };

   const handleUpload = async (event, index, document) => {
      const code = document.code;
      const entityType = document.entityType;
      let matchMissingFile = null;
      let id = null;
      const curDoc = uploadedDocuments
         .filter((item) => item.documentCode === document.code)
         .shift();
      if (curDoc && curDoc.missing) {
         matchMissingFile = curDoc.missing;
         id = curDoc.id;
      }
      //console.log(matchMissingFile, id);
      setSbShow(<></>);
      //console.log(event.target.files);
      const files = [...event.target.files];
      //console.log('files', files);
      const file = event.target.files[0];
      //console.log('files', files);
      event.target.value = null;
      //console.log('files', files);
      setIsPending(true);

      try {
         // UPLOAD
         //console.log(file)
         if (file.size > 200000000) {
            handleStatusBar('error', translate('common.error.filecannotexceed200', 'Il file non può superare i 200MB'), true);
            setIsPending(false);
            return false;
         }

         //console.log('o', files);
         let uploadPromise = [];

         files.forEach((file) => {
            uploadPromise.push(
               uploadDocument(entityType, entityId, code, file, matchMissingFile, id)
            );
         });

         const result = await Promise.allSettled(uploadPromise);
         console.log('result', result);

         let errorMessage = [];
         let message = [];

         result.forEach((item) => {
            if (item.status === "rejected") {
               //console.log('item', item);
               errorMessage.push(item.reason.message);
            }
            if (item.status === "fulfilled") {
               message.push(item);
            }
         });

         //console.log('errorMessage', errorMessage);
         //console.log('message', message);

         if (errorMessage.length > 0) {
            handleStatusBar("error", errorMessage.join(", "), true);
            setIsPending(false);
         }

         if (message.length > 0) {
            let messages = [];
            
            if (message.some(el => el.value.success === false)) {
               messages = message.map(el => el.value.message);
               handleStatusBar("error", messages.join(", "), true);
               setIsPending(false);
            } else {
               messages = message.map(el => el.value.message);
               handleStatusBar("success", messages.join(", "), true);
               setIsPending(false);
            }
         }

         getUploadedDocuments();
      } catch (error) {
         setIsPending(false);
         console.log(error);
      }
   };

   const handleDownload = async (event, upDocument) => {
      setSbShow(<></>);
      setIsPending(true);

      try {
         // UPLOAD
         const result = await downloadDocument(upDocument);

         if (result.success) {
            // File upload successful
            handleStatusBar("success", "Documento scaricato con successo", true);
            setIsPending(false);
         } else {
            const message = result.data.file.join(",");
            handleStatusBar("error", message, false);
            setIsPending(false);
         }
      } catch (error) { }
   };

   const handleDelete = async (event, upDocument) => {
      setSbShow(<></>);
      setIsPending(true);

      try {
         const result = await deleteDocument(upDocument);

         if (result.success) {
            handleStatusBar("success", "Documento eliminato con successo", true);

            //const newList = uploadedDocuments.filter((item) => item.id !== upDocument.id);
            //setUploadedDocuments(newList);
         } else {
            const message = result.data.file.join(",");
            handleStatusBar("error", message, false);
         }

         setIsPending(false);
         getUploadedDocuments();
      } catch (error) { }
   };

   const handlePreview = async (event, upDocument) => {
      //console.log("upDocument", upDocument);
      setSbShow(<></>);
      setIsPending(true);
      setPreviewMode(true);
      const response = await documentPreview(upDocument);
      const blob = await response;
      setPreviewBlob(blob);
      setPreviewMimeType(determineMimeType(upDocument));
      setIsPending(false);
   };

   const showDocumentList = (section) => {
      const tempDocuments = section ? oeDocuments.filter(doc => doc.section === section) : oeDocuments;

      return tempDocuments.map((document, index) => (
         <Col key={`oedoc-${index}_${entityId}`} sm={12}>
            <Form.Group
               as={Col}
               className="px-3"
               controlId={`${document.code}_${document.entityType}_${entityId}`}
            >
               <Form.Label className="mb-2 me-1">
                  {disableEdit ? null : (Number(userRole) <= 40 ? (   
                     <>
                        <span className="required-field position-relative">
                           <Form.Label>
                              <IcoUploadFile
                                 style={{
                                    cursor: "pointer",
                                 }}
                                 className={`ico-small download-upload align-bottom ${isPending || isValidated ? 'ico-disabled' : 'second-color'}`}
                              />
                           </Form.Label>
                           <Form.Control
                              type="file"
                              disabled={
                                 isPending || isValidated ? true : false
                              }
                              style={{ display: "none" }}
                              multiple
                              onChange={(e) =>
                                 handleUpload(e, index, document)
                              }
                           />
                        </span>
                        &nbsp;
                     </>
                  ) : null)}
                  {document.name}
                  &nbsp;
                  <span
                     className={
                        counter[document.code] < document.min ||
                           counter[document.code] === undefined
                           ? "danger"
                           : "required-field position-relative"
                     }
                  >
                     {/* {document.min > 0 ? 
               (counter[document.code] === undefined ? 
                  0 + "/" + document.min : counter[document.code] + "/" + document.min)
                : ""} */}
                     {document.min > 0 ? <span className="ps-1">min. {document.min}</span> : ""}
                  </span>
               </Form.Label>
               <ol className="ps-3">
                  {uploadedDocuments.map((upDocument, index) =>
                     upDocument.documentCode === document.code ? (
                        <Fragment
                           key={`docs-${index}_${document.code}_${entityId}`}
                        >
                           <li className="d-flex justify-content-between">
                              <Col className="col-12 mb-1">
                                 {enableUpload && !isValidated && upDocument.missing && (
                                    <span>
                                       <span style={{ margin: "8px" }} />
                                      
                                       <IcoUploadFile
                                          style={{cursor: "pointer"}}
                                          onClick={(e) => {
                                             setSelectedDocumentId(upDocument.id);
                                             e.stopPropagation(); // Impedisce la propagazione dell'evento
                                             if (!isPending && fileInputRef.current[index]) {
                                                fileInputRef.current[index].click();
                                             }
                                          }}
                                          className={`ico-small download-upload align-bottom ${isPending ? 'ico-disabled' : 'second-color'}`}
                                       />
                                       
                                       <Form.Control
                                          type="file"
                                          ref={(el) => (fileInputRef.current[index] = el)} // Assegna il ref all'input
                                          disabled={isPending}
                                          style={{ display: "none" }}
                                          onChange={(e) => {
                                             if (fileInputRef.current[index]) {
                                                fileInputRef.current[index].focus(); // Focalizza l'elemento corrispondente
                                              }
                                             handleUploadDocument(
                                                e,
                                                selectedDocumentId
                                             )
                                          }}
                                       />
                                    </span>
                                 )}
                                 {!upDocument.missing && (
                                    <span>
                                       <span style={{ margin: "8px" }} />
                                       <Eye
                                          className={`ico-small download-upload ${isPending ||
                                             upDocument.fileExtension === "zip" || upDocument.fileExtension === '7z'
                                             ? "ico-disabled"
                                             : "ico-enabled"
                                             }`}
                                          {...(upDocument.fileExtension === "zip" || upDocument.fileExtension === '7z'

                                             ? {}
                                             : {
                                                onClick: (e) =>
                                                   handlePreview(e, upDocument),
                                             })}
                                       />
                                       <span style={{ margin: "8px" }} />
                                       <CloudArrowDown
                                          style={{
                                             cursor: "pointer",
                                             fill: "#09A8B0",
                                          }}
                                          className={
                                             isPending
                                                ? "ico-disabled ico-small download-upload"
                                                : "second-color ico-small download-upload"
                                          }
                                          onClick={(e) =>
                                             handleDownload(e, upDocument)
                                          }
                                       />
                                       <span style={{ margin: "8px" }} />
                                       {userRole <= 40 && !disableDelete && (
                                          <IcoCanc
                                             style={{
                                                cursor: "pointer",
                                                viewBox: "0 0 24 24",
                                                fill: "#09A8B0",
                                                transform: "translate(0, -1px)",
                                                marginRight: "15px",
                                                width: "24px",
                                                height: "24px",
                                             }}
                                             className={
                                                isPending
                                                   ? "disabled ico-small download-upload"
                                                   : "second-color ico-small download-upload"
                                             }
                                             onClick={(e) =>
                                                handleDelete(e, upDocument)
                                             }
                                          />
                                       )}
                                    </span>
                                 )}
                                 {/* Nome del file */}
                                 <span className="ms-2">
                                    {displayName && upDocument.name && (
                                       <>
                                          <strong>{upDocument.name}</strong>{" "}
                                          {" | "}
                                       </>
                                    )}
                                    {upDocument.missing && upDocument.description ? upDocument.description : upDocument.fileName}
                                 </span>

                                 {/* Badge per file mancante */}
                                 {upDocument.missing !== undefined &&
                                    upDocument.missing && (
                                       <span className="badge bg-danger ms-2">
                                          File mancante
                                       </span>
                                    )}
                              </Col>
                           </li>
                        </Fragment>
                     ) : null
                  )}
               </ol>
            </Form.Group>
         </Col>
      ))
   };

   return (
      <>
         {previewMode && (
            <div style={{ height: '95%' }}>
               <EyeSlash
                  style={{ cursor: "pointer", fill: "#09A8B0" }}
                  className={`ico-small download-upload ${isPending ? "ico-disabled" : "second-color"
                     }`}
                  onClick={(e) => setPreviewMode(false)}
               />
               <OEIframePreview blob={previewBlob} mimeType={previewMimeType} />
            </div>
         )}
         {!previewMode && (
            <Form
               key={`key-upload-docs-${entityId}`}
               id={`form-upload-docs-${entityId}`}
            >
               <Row>
                  {/* {isPending && <Spinner animation="border" role="status" style={{display:'flex',margin:'0 auto'}}></Spinner>}  */}
                  <Col
                     className={`col-12 ${!externalBorder ? "" : "border-tab right"
                        }`}
                  >
                     <Row>
                        {sbShow}

                        {isPending && (
                           <Spinner
                              animation="border"
                              role="status"
                              style={{ display: "flex", margin: "0 auto" }}
                           ></Spinner>
                        )}

                        {groupedBySection ? (
                           sections.map(section => {
                              return <Fragment key={section}>
                                 {section && <span className="mb-2rem">{section}</span>}

                                 {showDocumentList(section)}
                              </Fragment>
                           })
                        ) : (<>
                           {title && <span className="mb-2rem">{title}</span>}

                           {showDocumentList()}
                        </>
                        )}

                     </Row>
                  </Col>

                  {false && (
                     <Col className="col-6 py-3 text-center text-muted">
                        {/* <PdfViewer pdfUrl='/Users/valeriomantini/Downloads/dummy.pdf' /> */}
                     </Col>
                  )}
               </Row>
            </Form>
         )}
      </>
   );
};

export default UploadDocument;
