import React, { useState, useEffect } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import {
  editTender,
  deleteTender,
  getTenderCigs,
  createTenderCig,
  editTenderCig,
  deleteTenderCig,
  editQuestionSetByEntity,
  getTender,
} from "@lib/api";
import Select from "react-select";
import { InputNumber } from "primereact";
import { IcoCanc, IcoEdit, IcoMinusCircled, IcoPlus } from "@icons";
import { dateFormatterInput } from "@lib/helpers/formatting";
import CheckList from "@components/checklist/checkList";
import UploadDocument from "@components/form/upload/uploadDocuments";
import MassiveUpload from "@components/form/upload/massiveUpload";
import OeApiResponseRenderer from "@components/OeApiResponseRenderer";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const ActionBtnRendererTenders = ({
  data,
  setRefresh,
  optionsCompanies,
  optionsProcedures,
  optionsCriteria,
  optionsClassifications,
  optionsQuestionSet,
  localLang,
  showButtons = true,
}) => {
  const id = data.id;
  const [tender, setTender] = useState({});
  const [cigs, setCigs] = useState([]);
  const [originCigs, setOriginCigs] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeKey, setActiveKey] = useState("registry");
  const [isReported, setIsReported] = useState(false);
  const [isUsed, setIsUsed] = useState(false);

  useEffect(() => {
   if (data) setIsUsed(!data.deletable);
   
    if (id && showModalEdit) {
      getTender(id).then((retrievedTender) => {
         if (retrievedTender.success) {
            setTender(retrievedTender.data);
            setIsUsed(!data.deletable);
         }
      });

      getTenderCigs(id).then((retrievedCigs) => {
        setCigs(retrievedCigs.data);
        setOriginCigs(retrievedCigs.data);
      });
    }
  }, [data, id, showModalEdit]);

  useEffect(() => {
    if (tender.id) {
      setIsReported(tender.reported);
    }
  }, [tender]);

  useEffect(() => {
    setSelectedCompany(
      optionsCompanies.filter((option) => option.value === tender.companyId)
    );

    setSelectedProcedure(
      optionsProcedures
        .filter((option) => option.value === tender.assignmentProcedureCode)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedClassification(
      optionsClassifications
        .filter((option) => option.value === tender.tenderClassification)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedCriteria(
      optionsCriteria
        .filter((option) => option.value === tender.assignmentCriteriaCode)
        .map((filteredOpt) => ({
          value: filteredOpt.value,
          label: filteredOpt.label,
          code: filteredOpt.code,
        }))
    );

    setSelectedQuestionSet(optionsQuestionSet.filter((option) => option.value === tender.questionsetId));
  }, [
    optionsClassifications,
    optionsCompanies,
    optionsCriteria,
    optionsProcedures,
    optionsQuestionSet,
    tender,
  ]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setActiveKey("registry");
    setRefresh(true);
    setShowModal(false);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleShowModalTab = (setShowModal, tab) => {
    setShowModal(true);
    setActiveKey(tab);
  };

  const handleStatusBar = (dataToHandle) => {
    setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
  };

  const onChange = (e, fieldName, setSelect, elIndex, list) => {
    //console.log('e', e);

    let tempTender = { ...tender };
    let value;

    switch (fieldName) {
      case "tenderNumber":
      case "subject":
      case "qualificationSystem":
      case "callDate":
      case "assignmentDate":
      case "referenceAct":
        value = e.target.value;

        tempTender[fieldName] = value;
        break;
      case "companyId":
      case "assignmentProcedureCode":
      case "tenderClassification":
      case "assignmentCriteriaCode":
        value = e ? e.value : "";

        tempTender[fieldName] = value;

        setSelect(e);

        tempTender[fieldName] = value;
        break;
      case "import":
      case "auctionImport":
      case "totalImport":
        value = Number(e.value);

        tempTender[fieldName] = value;
        break;
      case "cigs":
        let tempList = [...list];

        let tempElement = {
          ...tempList[elIndex],
          [e.target.name]: e.target.value,
          tenderId: tender.id,
        };

        tempList[elIndex] = tempElement;

        setSelect(tempList); //as setList
        break;
      case "dedicatedPnrr":
      case "officialGazettePubItaly":
      case "officialGazettePubEurope":
        value = e.target.checked;
        tempTender[fieldName] = value;
        break;
      case "questionsetId":
         value = e ? e.value : null;
         tempTender[fieldName] = value;

         setSelect(e);
         break;
      default:
        break;
    }

    setTender(tempTender);
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restTender } =
      tender;

    if (!tender.companyId || !tender.tenderNumber || !tender.questionsetId) {
      handleStatusBar({
        success: false,
        message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
      });
      return;
    }

    const isCigsFilled = cigs.length > 0 ? true : false;

    const set = new Set();
    const isNotNameCigsUnique = cigs.some(
      (object) => set.size === (set.add(object.cig), set.size)
    );

    if (!isCigsFilled) {
      handleStatusBar({
        success: false,
        message: translate('common.error.cigbepresent', 'Deve essere presente almeno un CIG'),
      });
      return;
    }

    if (isNotNameCigsUnique) {
      handleStatusBar({
        success: false,
        message: translate('common.error.notsameCIG', 'I codici CIG non possono essere uguali'),
      });
      return;
    }

    setShowSpinner(true);
    const result = await editTender(restTender);

    if (result.success) {
      let resultOfActions = [true];

      let questionSetsResult;
      let questionSetsResultBool = true;

      if (tender.questionsetId !== Number(selectedQuestionSet.map(el => el.value).join())) {
         questionSetsResult = await editQuestionSetByEntity('tenders', tender.id, selectedQuestionSet[0].value);
         questionSetsResultBool = questionSetsResult.success;

      }

      if (!questionSetsResultBool) {
         handleStatusBar(questionSetsResult);
         return
      }

      let [cigsResults] = await Promise.all([
        handleItemList(
          "cigs",
          originCigs,
          cigs,
          deleteTenderCig,
          editTenderCig,
          createTenderCig,
          result.data.id
        ),
      ]);
      //console.log('cigsResults', cigsResults);

      const resultOfAll = resultOfActions.concat(cigsResults.resultOfActions, [questionSetsResultBool]);
      //console.log('resultOfAll', resultOfAll);

      if (resultOfAll.length > 0 && resultOfAll.every((el) => el === true)) {
        setTimeout(() => {
          getTenderCigs(result.data.id).then((retrievedCigs) => {
            setCigs(retrievedCigs.data);
            setOriginCigs(retrievedCigs.data);
          });

          getTender(id).then((retrievedTender) => {
            setTender(retrievedTender.data);
          });
        }, 500);

        handleStatusBar({
          success: true,
          message: translate('common.message.edited', 'Elemento modificato con successo'),
        });
      } else {
        handleStatusBar(cigsResults.messageResult);
      }
    } else {
      handleStatusBar(result);
    }

    setShowSpinner(false);
  };

  const handleItemList = async (
    entityType,
    originItemList,
    itemList,
    deleteAPI,
    editAPI,
    createAPI,
    entityId
  ) => {
    let resultOfActions = [];

    const oldItemsDeleted = originItemList.filter((object1) => {
      return !itemList.some((object2) => {
        return object1.id === object2.id;
      });
    });

    if (oldItemsDeleted.length > 0) {
      oldItemsDeleted.forEach(async (item) => {
        const deleteResult = await deleteAPI(entityId, item.id);
        resultOfActions.push(deleteResult.success);
      });
    }

    let validationFields = [];

    switch (entityType) {
      case "cigs":
        validationFields = ["cig"];
        break;
      default:
        validationFields = [];
    }

    let result;
    let messageResult;

    await Promise.all(
      itemList.map(async (item) => {
        const resultOfEvery = validationFields.every((field) => item[field]);
        //console.log('resultOfEvery', resultOfEvery);

        if (resultOfEvery) {
          if (item.id) {
            const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } =
              item;
            result = await editAPI(entityId, restItem);
            //console.log('result edit:', result);
            if (result.success === false) messageResult = result;
            resultOfActions.push(result.success);
          } else {
            item.tenderId = entityId;
            result = await createAPI(entityId, item);
            //console.log('result create:', result);
            if (result.success === false) messageResult = result;
            resultOfActions.push(result.success);
          }
        } else {
          messageResult = {
            success: false,
            data: { validation: [translate('common.error.fillfields', 'Azione non eseguita. Riempi tutti i campi')] },
          };
          resultOfActions.push(false);
          return;
        }
      })
    );

    //console.log('messageResult:', messageResult);
    return { resultOfActions, messageResult };
  };

  const handleDelete = async () => {
    const deleteResult = await deleteTender(id);
    //console.log('deleteResult', deleteResult);

    if (deleteResult.success) {
      handleStatusBar({
        success: true,
        message: translate('common.message.deleted', 'Elemento eliminato correttamente'),
      });

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(deleteResult);
    }
  };

  const editTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.edit' fallback={'Modifica'}/></Tooltip>;
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      {isUsed ? "Gara in uso" : <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>}
    </Tooltip>
  );

  const addElement = (typeArray, setTypeArray) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.push({
      cig: "",
      lot: "",
      tenderId: tender.id,
      deletable: true,
    });

    setTypeArray(tempTypeArray);
  };

  const removeElement = (typeArray, setTypeArray, index) => {
    let tempTypeArray = [...typeArray];

    tempTypeArray.splice(index, 1);

    setTypeArray(tempTypeArray);
  };

  const cigsRows = (cigs, setCigs) => {
    let rows = [];

    if (cigs) {
      if (cigs.length === 0) {
        cigs = [{ cig: "", lot: "", tenderId: tender.id, deletable: true }];
      }

      //console.log('cigs', cigs);
      let lastIndex = cigs.length - 1;

      rows = cigs.map((el, i) => {
        const isThisRowFilled = el.cig ? true : false;

        return (
          <Row key={"cig-" + i} style={{ marginBottom: "40px" }}>
            <Col className="col-6">
              <Form.Control
                type="text"
                name="cig"
                placeholder={translate('common.placeholder.tenders.cigcode', 'codice CIG')}
                value={el.cig ?? ""}
                onChange={(e) => onChange(e, "cigs", setCigs, i, cigs)}
                disabled={!el.deletable}
              />
            </Col>
            <Col className="col-4">
              <Form.Control
                type="text"
                name="lot"
                placeholder={translate('common.placeholder.tenders.lot', 'lotto (opzionale)')}
                value={el.lot ?? ""}
                onChange={(e) => onChange(e, "cigs", setCigs, i, cigs)}
                disabled={!el.deletable}
              />
            </Col>
            <Col className="col-2">
              {i === lastIndex ? (
                <Button
                  variant="link"
                  className="me-3"
                  onClick={() => addElement(cigs, setCigs)}
                  disabled={!isThisRowFilled}
                >
                  <IcoPlus className="second-color ico-small indicators" />
                </Button>
              ) : null}
              {cigs.length !== 1 && el.deletable ? (
                <Button
                  variant="link"
                  onClick={() => removeElement(cigs, setCigs, i)}
                >
                  <IcoMinusCircled className="red-color ico-small indicators" />
                </Button>
              ) : null}
            </Col>
          </Row>
        );
      });
    }

    return rows;
  };

  return (
    <div>
      {showButtons && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Button
            variant="outline-info"
            className="py-1 me-4 col-auto"
            onClick={() => handleShowModalTab(setShowModalEdit, "docs")}
          >
            <OETrans code='common.documents' fallback={'Documenti'} />
          </Button>
          <Button
            variant="outline-info"
            className="py-1 me-4 col-auto"
            onClick={() =>
              handleShowModalTab(setShowModalEdit, "frameworkContracts")
            }
          >
            <OETrans code='common.frameworkcontracts' fallback={'Contratti aperti'} />
          </Button>

          <OverlayTrigger placement="top" overlay={editTooltip}>
            <span>
              <Button
                variant="link"
                className="me-3 mb-1 col-auto"
                onClick={() => handleShowModal(setShowModalEdit)}
              >
                <IcoEdit className="second-color ico-small edit" />
              </Button>
            </span>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={deleteTooltip}>
            <span>
              <Button
                variant="link"
                className="me-0 mb-1"
                onClick={() => handleShowModal(setShowModalDelete)}
                disabled={isUsed}
              >
                <IcoCanc className="second-color ico-small cancel" />
              </Button>
            </span>
          </OverlayTrigger>
        </div>
      )}

      {showModalEdit && (
        <Modal
          size="xl"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><OETrans code='common.edittender' fallback={'Modifica gara'} /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              variant='pills'
              fill
            >
              {/* Anagrafica */}
              <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
                <Form onSubmit={handleEdit} id="form-new-tender">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='tenders.companyId' fallback={'Società'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="companyId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedCompany}
                          options={optionsCompanies}
                          onChange={(e) =>
                            onChange(e, "companyId", setSelectedCompany)
                          }
                          isDisabled={isReported || isUsed}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='tenders.tenderNumber' fallback={'Nr. gara'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="tenderNumber"
                          value={tender.tenderNumber ?? ''}
                          onChange={(e) => onChange(e, "tenderNumber")}
                          disabled={isReported || isUsed}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.tenderclassification' fallback={'Classificazione gara'}/></Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="tenderClassification"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedClassification}
                          options={optionsClassifications}
                          onChange={(e) =>
                            onChange(
                              e,
                              "tenderClassification",
                              setSelectedClassification
                            )
                          }
                          isDisabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.assignmentProcedureCode' fallback={'Tipologia procedura'}/></Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="assignmentProcedureCode"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedProcedure}
                          options={optionsProcedures}
                          onChange={(e) =>
                            onChange(
                              e,
                              "assignmentProcedureCode",
                              setSelectedProcedure
                            )
                          }
                          isDisabled={isReported}
                          isClearable
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.qualificationSystem' fallback={'Sistema di qualificazione'}/></Form.Label>
                        <Form.Control
                          type="text"
                          name="qualificationSystem"
                          value={tender.qualificationSystem ?? ""}
                          onChange={(e) => onChange(e, "qualificationSystem")}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.assignmentCriteriaCode' fallback={'Criterio di aggiudicazione'}/></Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="assignmentCriteriaCode"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedCriteria}
                          options={optionsCriteria}
                          onChange={(e) =>
                            onChange(
                              e,
                              "assignmentCriteriaCode",
                              setSelectedCriteria
                            )
                          }
                          isDisabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-3">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.callDate' fallback={'Data indizione'}/></Form.Label>
                        <Form.Control
                          type="date"
                          name="callDate"
                          value={
                            tender.callDate
                              ? dateFormatterInput(tender.callDate)
                              : ""
                          }
                          onChange={(e) => onChange(e, "callDate")}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.assignmentDate' fallback={'Data avviso aggiudicazione'}/></Form.Label>
                        <Form.Control
                          type="date"
                          name="assignmentDate"
                          value={
                            tender.assignmentDate
                              ? dateFormatterInput(tender.assignmentDate)
                              : ""
                          }
                          onChange={(e) => onChange(e, "assignmentDate")}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group as={Col} className="text-center">
                        <Form.Label><OETrans code='tenders.officialGazettePubItaly' fallback={'Pubblicazione gazzetta (GURI)'}/></Form.Label>
                        <Form.Check
                          className="text-center"
                          type="checkbox"
                          name="officialGazettePubItaly"
                          defaultChecked={tender.officialGazettePubItaly}
                          onClick={(e) =>
                            onChange(e, "officialGazettePubItaly")
                          }
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-3">
                      <Form.Group as={Col} className="text-center">
                        <Form.Label><OETrans code='tenders.officialGazettePubEurope' fallback={'Pubblicazione gazzetta (GUUE)'}/></Form.Label>
                        <Form.Check
                          className="text-center"
                          type="checkbox"
                          name="officialGazettePubEurope"
                          defaultChecked={tender.officialGazettePubEurope}
                          onClick={(e) =>
                            onChange(e, "officialGazettePubEurope")
                          }
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.auctionImport' fallback={'Importo base d’asta'}/></Form.Label>
                        <InputNumber
                          name="auctionImport"
                          min={0}
                          step={1}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          onChange={(e) => onChange(e, "auctionImport")}
                          mode="decimal"
                          locale={localLang}
                          className="md"
                          value={tender.auctionImport ?? ""}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.totalImport' fallback={'Importo complessivo gara'}/></Form.Label>
                        <InputNumber
                          name="totalImport"
                          min={0}
                          step={1}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          onChange={(e) => onChange(e, "totalImport")}
                          mode="decimal"
                          locale={localLang}
                          className="md"
                          value={tender.totalImport ?? ""}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.import' fallback={'Importo base'}/></Form.Label>
                        <InputNumber
                          name="import"
                          min={0}
                          step={1}
                          minFractionDigits={0}
                          maxFractionDigits={2}
                          onChange={(e) => onChange(e, "import")}
                          mode="decimal"
                          locale={localLang}
                          className="md"
                          value={tender.import ?? ""}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.referenceAct' fallback={'Atto'}/></Form.Label>
                        <Form.Control
                          type="text"
                          name="referenceAct"
                          value={tender.referenceAct ?? ""}
                          onChange={(e) => onChange(e, "referenceAct")}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="col-4">
                     <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='common.setchecklist' fallback={'Set Domande'}/>
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                           components={{
                           IndicatorSeparator: () => null,
                           }}
                           name="questionsetId"
                           placeholder={translate('common.placeholder.select', 'Seleziona...')}
                           value={selectedQuestionSet}
                           options={optionsQuestionSet}
                           className="react-select-container"
                           classNamePrefix="react-select"
                           onChange={(e) =>
                           onChange(e, "questionsetId", setSelectedQuestionSet)
                           }
                           isDisabled={isUsed}
                        />
                     </Form.Group>
                     </Col>
                    <Col className="col-4">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.subject' fallback={'Oggetto'}/></Form.Label>
                        <Form.Control
                          type="text"
                          name="subject"
                          value={tender.subject ?? ""}
                          onChange={(e) => onChange(e, "subject")}
                          disabled={isReported}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Col className="col-auto">
                      <Form.Group as={Col}>
                        <Form.Label><OETrans code='tenders.dedicatedPnrr' fallback={'Dedicata'}/></Form.Label>
                        <Form.Check
                          className="ms-3"
                          type="checkbox"
                          name="dedicatedPnrr"
                          defaultChecked={tender.dedicatedPnrr}
                          onClick={(e) => onChange(e, "dedicatedPnrr")}
                          disabled={isReported}
                          inline
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* CIG */}
              <Tab eventKey="cig" title={translate('common.tenders.cig', 'CIG')}>
                <Form onSubmit={handleEdit} id="form-new-tender">
                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='common.tenders.cig' fallback={'CIG'}/>
                        &nbsp;<span className="required-field">*</span>
                      </Form.Label>
                      {cigsRows(cigs, setCigs)}
                    </Form.Group>
                  </Row>
                </Form>
                {sbShow}
              </Tab>

              {/* Documenti */}
              <Tab eventKey="docs" title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                <UploadDocument entity="tenders" entityId={tender.id} groupedBySection={true} />
                {sbShow}
              </Tab>

              {/* Contratti aperti */}
              <Tab eventKey="frameworkContracts" title={translate('common.frameworkcontracts', 'Contratti aperti')}>
                <MassiveUpload
                  entityId={tender.id}
                  isSummary={isReported}
                  doDownload={true}
                />
                {/* {sbShow} */}
              </Tab>

              {/* Checklist */}
              <Tab eventKey="checklist" title={translate('common.checklist', 'Checklist')}>
                {activeKey === "checklist" && (
                  <CheckList
                    entityType="tenders"
                    entityId={id}
                    disabled={isReported}
                  />
                )}
              </Tab>
            </Tabs>

            {activeKey === "registry" && (
              <Row className="d-inline">
                <Col className="col-12">
                  <span className="label">
                    <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                  </span>
                </Col>
              </Row>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleCloseModal(setShowModalEdit)}
            >
              <OETrans code='common.cancel' fallback={'Annulla'}/>
            </Button>
            {activeKey === "checklist" ? (
              <Button
                disabled={isReported}
                type="submit"
                form="checklist-tenders"
                variant="primary"
              >
                <OETrans code='common.edit' fallback={'Modifica'}/>
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={(e) => handleEdit(e)}
                disabled={
                  activeKey === "docs" ||
                  activeKey === "frameworkContracts" ||
                  isReported
                }
              >
                <OETrans code='common.edit' fallback={'Modifica'}/>
                {showSpinner && (
                  <Spinner
                    as="span"
                    className="ms-2"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showModalDelete}
        onHide={() => handleCloseModal(setShowModalDelete)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalDelete)}
          >
            <OETrans code='common.cancel' fallback={'Annulla'}/>
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <OETrans code='common.delete' fallback={'Elimina'}/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ActionBtnRendererTenders;
