import React, { useState, useEffect, useContext } from "react";
import {
   Row,
   Col,
   Button,
   Tab,
   Tabs,
   Form,
   OverlayTrigger,
   Tooltip,
   Spinner,
} from "react-bootstrap";
import { IcoPlus, IcoMinusCircled } from "@icons";
import {
   getAllProjectNoticeExpenseItems,
   editProject,
   getAllProjectTarget,
   createProjectTarget,
   editProjectTarget,
   deleteProjectTarget,
   editApplicationEconomicFrameworkValue,
   getAllProjectInternalExpenseItems,
   createProjectInternalExpenseItems,
   editProjectInternalExpenseItems,
   deleteProjectInternalExpenseItems,
   getProjectStats,
} from "@lib/api";
import { InputNumber } from "primereact";
import StatusBar from "@components/StatusBar";
import moment from "moment";
import { dateFormatter, formatToDecimals } from "@lib/helpers/formatting";
import { LevelContext } from "components/contexts/LevelContext";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const Candidacy = ({ project, summaryWheelsValues, fetchDataProject }) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [targets, setTargets] = useState([]);
   const [internalExpenseItems, setInternalExpenseItems] = useState([]);
   const [originTargets, setOriginTargets] = useState([]);
   const [projectNoticeExpenseItems, setProjectNoticeExpenseItems] = useState([]);
   const [applicationEconomicFrameworkValues, setApplicationEconomicFrameworkValues] = useState([]);
   const [originInternalExpenseItems, setOriginInternalExpenseItems] = useState([]);
   const [projectAmounts, setProjectAmounts] = useState({
      totalAdmissibleAmount: 0,
      totalApprovedAmount: 0,
   });
   const [totalAmounts, setTotalAmounts] = useState({});
   const [totalControls, setTotalControls] = useState({});
   const [sbShow, setSbShow] = useState(<></>);
   const [rows, setRows] = useState([]);
   const [/* economicsInternalRows */, setEconomicsInternalRows] = useState([]);
   const localLang = `${localStorage.getItem("userLanguage").toLowerCase()}-${localStorage.getItem("userLanguage")}`;
   //console.log('applicationEconomicFrameworkValues', applicationEconomicFrameworkValues);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   useEffect(() => {
      if (project.id) {
         let tempApplicationEconomicFrameworkValues = [...project.applicationEconomicFrameworkValues];

         tempApplicationEconomicFrameworkValues = tempApplicationEconomicFrameworkValues.map(el => ({
            ...el, 
            requiredAmount: (el.approvedAmount * el.intensity) / 100,
         }));
         
         setApplicationEconomicFrameworkValues(tempApplicationEconomicFrameworkValues);

         getAllProjectInternalExpenseItems(project.id).then(
            (internalExpenseItems) => {
               setInternalExpenseItems(internalExpenseItems.data);
               setOriginInternalExpenseItems(internalExpenseItems.data);
            }
         );

         getAllProjectNoticeExpenseItems(project.id).then((noticeExpenseItems) => {
            setProjectNoticeExpenseItems(
               noticeExpenseItems.data.map((noticeExpenseItem) => ({
                  id: noticeExpenseItem.id,
                  name: noticeExpenseItem.name,
                  projectId: noticeExpenseItem.projectId,
                  noticeExpenseItemId: noticeExpenseItem.noticeExpenseItemId,
                  position: noticeExpenseItem.position,
               }))
            );
         });

         getAllProjectTarget(project.id).then((targets) => {
            const tempTargets = targets.data.map((target) => ({
               id: target.id,
               name: target.name,
               description: target.description,
               uom: target.uom,
               projectId: target.projectId,
               noticeTargetId: target.noticeTargetId,
               targetValue: target.targetValue,
               targetDate: target.targetDate,
            }));

            setTargets(tempTargets);
            setOriginTargets(tempTargets);
         });

         setProjectAmounts({
            totalAdmissibleAmount: project.totalAdmissibleAmount ?? 0,
            totalApprovedAmount: project.totalApprovedAmount ?? 0,
         });
      }
   }, [project]);

   useEffect(() => {
      if (summaryWheelsValues.length > 0) {
         setTotalControls({
            totalAmount: summaryWheelsValues
               .filter((el) => el.propertyName === "totalAmount")
               .map((el) => el.propertyValue ?? 0)
               .pop(),
            totalApprovedAmount: summaryWheelsValues
               .filter((el) => el.propertyName === "totalApprovedAmount")
               .map((el) => el.propertyValue ?? 0)
               .pop(),
         });
      } else if (summaryWheelsValues.length === 0 && project.id) {
         getProjectStats(project.id).then((stats) => {
            if (stats.success) {
               setTotalControls({
                  totalAmount: stats.data.totalAmount,
                  totalApprovedAmount: stats.data.totalApprovedAmount,
               });
            }
         });
      }
   }, [project.id, summaryWheelsValues]);

   useEffect(() => {
      setRows(targets);
      totalPerColumn();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [targets]);
   useEffect(() => {
      setEconomicsInternalRows(internalExpenseItems);
      // totalPerColumn();
   }, [internalExpenseItems]);

   const ConditionalWrapper = ({ condition, wrapper, children }) =>
      condition ? wrapper(children) : children;

   const saveEconomicsFramework = async () => {
      setSbShow(<></>);
      projectAmounts._method = "PUT";
      const projectResult = await editProject(project.id, projectAmounts);

      if (projectResult.success) {
         delete projectAmounts._method;
         handleStatusBar("success", "Progetto modificato con successo", true);
      } else {
         handleStatusBar(
            "error",
            "Non è stato possibile aggiornare gli importi",
            false
         );
      }

      applicationEconomicFrameworkValues.forEach(
         async (applicationEconomicFrameworkValue) => {
            applicationEconomicFrameworkValue._method = "PUT";
            delete applicationEconomicFrameworkValue.createdAt;
            delete applicationEconomicFrameworkValue.createdBy;
            delete applicationEconomicFrameworkValue.updatedAt;
            delete applicationEconomicFrameworkValue.updatedBy;

            const result = await editApplicationEconomicFrameworkValue(
               project.id,
               applicationEconomicFrameworkValue
            );
            if (result.success) {
               handleStatusBar("success", "Importi modificati con successo", true);
            }
         }
      );

      //per aggiornare i valori di applicationEconomicFrameworkValue appena salvati
      setTimeout(() => {
         fetchDataProject();
      }, 1000); 
   };

   const onChange = (e, id, field) => {
      let value = e.value ?? 0;

      const tempApplicationEconomicFrameworkValues = [
         ...applicationEconomicFrameworkValues,
      ];
      const index = tempApplicationEconomicFrameworkValues.findIndex(
         (el) => el.id === id
      );

      value = field === 'intensity' && value > 100 ? 100 : value;

      tempApplicationEconomicFrameworkValues[index][field] = value;

      if (field === 'approvedAmount' || field === 'intensity') { 
         tempApplicationEconomicFrameworkValues[index].requiredAmount = (tempApplicationEconomicFrameworkValues[index].approvedAmount * tempApplicationEconomicFrameworkValues[index].intensity) / 100;
      };
      
      setApplicationEconomicFrameworkValues(tempApplicationEconomicFrameworkValues);

      totalPerColumn();
   };

   const handleSave = async () => {
      setSbShow(<></>);
      /* projectAmounts._method = 'PUT';
          const projectResult = await editProject(project.id, projectAmounts);
    
          if (projectResult.success) {
             delete projectAmounts._method;
             handleStatusBar('success', 'Informazioni modificate con successo', true);
          } else {
             handleStatusBar('error', 'Non è stato possibile aggiornare gli importi', false);
          } */

      if (!rows.every((el) => el.name && el.uom)) {
         handleStatusBar(
            "error",
            `Nome e unità di misura sono obbligatori`,
            false
         );
         return;
      }

      let resultOfActions = [true];
      let resultOfMessages = [];

      let targetsCleaned = rows.filter((target) => target.name && target.uom);

      let [targetsResult] = await Promise.all([
         handleItemList(
            "targets",
            originTargets,
            targetsCleaned,
            deleteProjectTarget,
            editProjectTarget,
            createProjectTarget
         ),
      ]);

      const resultOfAll = resultOfActions.concat(
         targetsResult ? targetsResult.resultOfActionsTemp : []
      );
      const resultOfAllMessages = resultOfMessages.concat(
         targetsResult ? targetsResult.messageResult : []
      );

      if (
         resultOfAll.length === 0 ||
         (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))
      ) {
         setTimeout(() => {
            getAllProjectTarget(project.id).then((targets) => {
               const tempTargets = targets.data.map((target) => ({
                  id: target.id,
                  name: target.name,
                  description: target.description,
                  uom: target.uom,
                  projectId: target.projectId,
                  noticeTargetId: target.noticeTargetId,
                  targetValue: target.targetValue,
                  targetDate: target.targetDate,
               }));

               setTargets(tempTargets);
               setOriginTargets(tempTargets);
            });
         }, 500);

         handleStatusBar("success", "Target modificati con successo", true);
      } else {
         handleStatusBar(
            "error",
            resultOfAllMessages.length > 0
               ? Object.values(resultOfAllMessages[0].data).join()
               : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
      }

      /* rows.forEach(async (target) => {
             if (target.isNew) {
                delete target.isNew;
                const createResult = await createProjectTarget(project.id, target);
                if (createResult.success) {
                   handleStatusBar('success', `Target ${target.name} aggiunto con successo`, true);
                } else {
                   console.log('createResult', Object.values(createResult.data));
                   handleStatusBar('error', `Non è stato possibile aggiungere il target ${target.name}`, false);
                }
             } else {
                target._method = 'PUT';
                //delete target.targetDate; //???
    
                const editResult = await editProjectTarget(project.id, target);
                delete target._method;
                if (editResult.success) {
                   handleStatusBar('success', `Target ${target.name} modificato con successo`, true);
                } else {
                   handleStatusBar('error', `Non è stato possibile modificare il target ${target.name}`, false);
                }
             }
          }); */
   };

   const handleItemList = async (
      entityType,
      originItemList,
      itemList,
      deleteAPI,
      editAPI,
      createAPI
   ) => {
      let resultOfActionsTemp = [];

      const oldItemsDeleted = originItemList.filter((object1) => {
         return !itemList.some((object2) => {
            return object1.id === object2.id;
         });
      });

      if (oldItemsDeleted.length > 0) {
         oldItemsDeleted.forEach(async (item) => {
            const deleteResult = await deleteAPI(project.id, item.id);
            resultOfActionsTemp.push(deleteResult.success);
         });
      }

      let validationFields = [];

      switch (entityType) {
         case "targets":
            validationFields = ["name", "uom"];
            break;
         case "internalExp":
            validationFields = ["name"];
            break;
         default:
            validationFields = [];
      }

      let result;
      let messageResult;

      itemList.forEach(async (item) => {
         if (
            validationFields.every(
               (field) =>
                  item[field] ||
                  (Array.isArray(item[field]) && item[field].every((el) => el))
            )
         ) {
            if (!item.isNew) {
               const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } =
                  item;
               result = await editAPI(project.id, restItem);
               //console.log('result:', result);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            } else {
               const { isNew, ...restNewItem } = item;
               result = await createAPI(project.id, restNewItem);
               //console.log('result:', result);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            }
         } else {
            //console.log('non riempiti');
            messageResult = {
               success: false,
               data: { validation: [`${entityType} ${translate('common.error.notsaved', 'non salvati')}`] },
            };
            resultOfActionsTemp.push(false);
            return;
         }
      });

      //console.log('messageResult', messageResult);
      return { resultOfActionsTemp, messageResult };
   };

   const totalPerColumn = () => {
      const total = applicationEconomicFrameworkValues.reduce(
         (acc, { amount, admissibleAmount, approvedAmount, requiredAmount }) => {
            return {
               projectAmount: acc.projectAmount + amount,
               admissibleAmount: acc.admissibleAmount + admissibleAmount,
               approvedAmount: acc.approvedAmount + approvedAmount,
               requiredAmount: acc.requiredAmount + requiredAmount,
            };
         },
         { projectAmount: 0, admissibleAmount: 0, approvedAmount: 0, requiredAmount: 0 }
      );

      setTotalAmounts(total);
      setProjectAmounts({
         ...projectAmounts,
         totalApprovedAmount: total.requiredAmount ?? 0,
      });
   };

   const economicsTab = () => {
      const colHeader = [<th key="th-empty"></th>];

      //let colHeaderX = expenseItems.map((el) => el.year);
      //colHeaderX = colHeaderX.filter((item, index) => colHeaderX.indexOf(item) === index);
      let colHeaderX = [
         "Costo progetto (€)",
         "Costo ammissibile (€)",
         "Costo approvato (€)",
         "Intensità d'aiuto (%)",
         "Contributo richiesto (€)",
      ];

      colHeader.push(
         colHeaderX.map((colH) => (
            <th
               key={colH}
               className={`${(!isOe && userRole !== 5) ? "text-end" : ''} no-to-head cell-width-economic-data f-600 text-end`}
            >
               {colH}
            </th>
         ))
      );

      let rowHeaderX = projectNoticeExpenseItems.sort((a, b) => {
         return a.position - b.position;
      });

      rowHeaderX = rowHeaderX.filter(
         (item, index) => rowHeaderX.indexOf(item) === index
      );

      return (
         <div>
            <table className="show-economics-tab">
               <thead>
                  <tr>{colHeader}</tr>
               </thead>
               <tbody className="text-end">
                  {rowHeaderX.map((rowH, index) => {
                     return (
                        <tr key={`${rowH.id}-${rowH.name}`}>
                           <td className="pl-2 f-600 py-3">{rowH.name}</td>
                           {applicationEconomicFrameworkValues
                              .filter((el) => rowH.id === Number(el.projectNoticeExpenseItemId))
                              .map((el) => {
                                 return (
                                 <React.Fragment key={`el-${el.id}`}>
                                    {(isOe || userRole === 5) ? (
                                       <>
                                          <td>
                                             <InputNumber
                                                id={el.id}
                                                key={el.id}
                                                name="amount"
                                                min={0}
                                                step={1}
                                                minFractionDigits={2}
                                                maxFractionDigits={2}
                                                onChange={(e) => onChange(e, el.id, "amount")}
                                                mode="decimal"
                                                locale={localLang}
                                                className="ml input-candidacy justify-content-end"
                                                value={el.amount ?? 0}
                                             />
                                          </td>
                                          <td>
                                             <InputNumber
                                                key={el.id}
                                                name="admissibleAmount"
                                                min={0}
                                                step={1}
                                                minFractionDigits={2}
                                                maxFractionDigits={2}
                                                //disabled
                                                //readOnly
                                                onChange={(e) =>
                                                   onChange(e, el.id, "admissibleAmount")
                                                }
                                                mode="decimal"
                                                locale={localLang}
                                                className="md input-candidacy justify-content-end"
                                                value={el.admissibleAmount ?? 0}
                                             />
                                          </td>
                                          <td>
                                             <InputNumber
                                                key={el.id}
                                                name="approvedAmount"
                                                min={0}
                                                step={1}
                                                minFractionDigits={2}
                                                maxFractionDigits={2}
                                                onChange={(e) =>
                                                   onChange(e, el.id, "approvedAmount")
                                                }
                                                mode="decimal"
                                                locale={localLang}
                                                className="md input-candidacy justify-content-end"
                                                value={el.approvedAmount ?? 0}
                                             />
                                          </td>
                                          <td>
                                             <InputNumber
                                                name="intensity"
                                                step={1}
                                                minFractionDigits={2}
                                                maxFractionDigits={10}
                                                onChange={(e) => onChange(e, el.id, "intensity")}
                                                mode="decimal"
                                                locale={localLang}
                                                className="ml input-candidacy justify-content-end"
                                                value={el.intensity ?? 0}
                                                min={0}
                                                max={100}
                                             />
                                          </td>
                                       </>
                                    ) : (
                                       <>
                                          <td className="py-3">
                                             <span className="text-end">{formatToDecimals(el.amount)}</span>
                                          </td>
                                          <td className="py-3">
                                             <span className="text-end">{formatToDecimals(el.admissibleAmount)}</span>
                                          </td>
                                          <td className="py-3">
                                             <span className="text-end">{formatToDecimals(el.approvedAmount)}</span>
                                          </td>
                                          <td className="py-3">
                                             <span className="text-end">{formatToDecimals(el.intensity)}</span>
                                          </td>
                                       </>
                                    )}
                                    <td className="py-3">
                                       <span className="text-end">{formatToDecimals(el.requiredAmount ?? 0)}</span>
                                    </td>
                                 </React.Fragment>
                              )})}
                        </tr>
                     );
                  })}

                  <tr className="totals-row" style={{ borderTop: '1px solid #E6E6E6' }}>
                     <td className="text-left py-3">
                        <span className="mb-0 f-600" style={{ textTransform: "uppercase" }}>
                           Totale
                        </span>
                     </td>
                     <td className="text-end">
                        <div className={`${(!isOe && userRole !== 5) ? "me-0" : ''}`}>
                           {Object.keys(totalAmounts).length > 0 &&
                              Object.keys(totalControls).length > 0 ? (
                              <ConditionalWrapper
                                 condition={false} //totalAmounts.projectAmount !== totalControls?.totalAmount (da concordare)
                                 wrapper={(children) => (
                                    <OverlayTrigger
                                       placement="bottom"
                                       overlay={
                                          <Tooltip>
                                             Il valore non corrisponde a quanto presente in
                                             Dashboard:
                                             <br />
                                             {formatToDecimals(totalControls?.totalAmount)} €
                                          </Tooltip>
                                       }
                                    >
                                       <span className="text-danger">{children}</span>
                                    </OverlayTrigger>
                                 )}
                              >
                                 <span className="f-600" style={{ paddingRight: !isOe && userRole !== 5 ? '0' : '13px' }}>
                                    {formatToDecimals(totalAmounts.projectAmount)}
                                 </span>
                              </ConditionalWrapper>
                           ) :
                              <Spinner animation='border' role='status' size="sm" style={{ marginTop: '0.5rem', marginRight: !isOe && userRole !== 5 ? '0' : '10px' }}>
                                <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                              </Spinner>
                           }
                        </div>
                     </td>
                     <td className="text-end py-4">
                        <div className={`f-600 ${(!isOe && userRole !== 5) ? "me-0" : ''}`}>
                           <span style={{ paddingRight: !isOe && userRole !== 5 ? '0' : '13px' }}>
                              {formatToDecimals(totalAmounts.admissibleAmount)}
                           </span>
                        </div>
                     </td>
                     <td className="text-end py-4">
                        <div className={`f-600 ${(!isOe && userRole !== 5) ? "me-0" : ''}`}>
                           <span>
                              {formatToDecimals(totalAmounts.approvedAmount)}
                           </span>
                        </div>
                     </td>
                     <td className="text-end py-3"></td>
                     <td className="text-end py-3"></td>
                  </tr>

                  <tr className="totals-row">
                     <td className="text-left py-3">
                        <span className="mb-0 f-600 no-to-head" style={{ textTransform: "uppercase" }}
                        >
                           Contributo concesso
                        </span>
                     </td>
                     <td className="text-end py-3"></td>
                     <td className="text-end py-3"></td>
                     <td className="text-end py-3"></td>
                     <td className="text-end py-3"></td>
                     <td className="text-end py-3 f-600">
                        <span>
                           {formatToDecimals(totalAmounts.requiredAmount ? totalAmounts.requiredAmount : 0)}
                        </span>
                     </td>
                  </tr>
               </tbody>
            </table>
            {(isOe || userRole === 5) && (
               <Row className="mt-5 mb-2 text-center">
                  <Col>
                     <Button onClick={saveEconomicsFramework}><OETrans code='common.save' fallback={'Salva'}/></Button>
                  </Col>
               </Row>
            )}
         </div>
      );
   };

   const targetRow = () => {
      const addRow = (id) => {
         /* const newRow = {
                  id: id,
                  name: '',
                  //description: '',
                  uom: '',
                  projectId: project.id,
                  noticeTargetId: null,
                  targetValue: 0,
                  targetDate: null,
                  isNew: true,
               };
               setRows([...rows, newRow]); */

         let tempTypeArray = [...rows];

         tempTypeArray.push({
            name: "",
            uom: "",
            projectId: project.id,
            targetValue: 0,
        /* targetDate: null, */ isNew: true,
         });

         setRows(tempTypeArray);
      };

      // Remove a row
      const removeRow = async (target, index) => {
         /* const updatedRows = rows.filter((row) => row.id !== target.id);
       
               if (target.isNew) {
                  setRows(updatedRows);
               } else {
                  const deleteResult = await deleteProjectTarget(project.id, target.id);
                  if (deleteResult.success) {
                     handleStatusBar('success', translate('common.message.deleted', 'Elemento eliminato correttamente'), true);
                     setRows(updatedRows);
                  } else {
                     handleStatusBar(
                        'error',
                        deleteResult.message ?? `Non è stato possibile eliminare il target`,
                        false
                     );
                  }
               } */

         let tempTypeArray = [...rows];

         tempTypeArray.splice(index, 1);

         setRows(tempTypeArray);
      };

      // Update input values
      const handleInputChange = (id, inputName, value, elIndex) => {
         //row.id, 'targetDate', e.target.value

         /* const updatedRows = rows.map((row) => {
                  if (row.id === id) {
                     return { ...row, [inputName]: value };
                  }
                  return row;
               });
               setRows(updatedRows); */

         let tempList = [...rows];

         let tempElement = {
            ...tempList[elIndex],
            [inputName]: value,
         };

         tempList[elIndex] = tempElement;

         setRows(tempList);
      };

      let lastIndex = rows.length - 1;

      return (
         <div>
            <table className="show-targets-tab">
               <thead>
                  <tr>
                     <th></th>
                     <th className="f-600 text-center">Data</th>
                     <th className="f-600">UOM</th>
                     <th className="f-600 text-end">Valore</th>
                     <th className="invisible">
                        <Button
                           variant="link"
                           onClick={() => null}
                           disabled={true}
                        >
                           <IcoPlus className="second-color ico-small indicators" />
                        </Button>
                        <Button
                           disabled={true}
                           variant="link"
                           onClick={() => null}
                        >
                           <IcoMinusCircled className="red-color ico-small indicators" />
                        </Button>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {rows.map((row, i) => {
                     const isThisRowFilled = row.name && row.uom ? true : false;
                     return (
                        <tr key={"target" + i}>
                           <td className="f-600">
                              {(isOe || userRole === 5) && !row.noticeTargetId ? (
                                 <Form.Control
                                    type="text"
                                    value={row.name}
                                    onChange={(e) =>
                                       handleInputChange(row.id, "name", e.target.value, i)
                                    }
                                 />
                              ) : (
                                 <div className="f-600">{row?.name ?? "N.D."}</div>
                              )}
                           </td>
                           <td>
                              {(isOe || userRole === 5) ? (
                                 <Form.Control
                                    type="date"
                                    name="targetDate"
                                    value={row.targetDate ? moment(row.targetDate).format("YYYY-MM-DD") : ""}
                                    onChange={(e) => handleInputChange(row.id, "targetDate", e.target.value, i)}
                                 />
                              ) : (
                                 <span className="mt-3">{row.targetDate ? dateFormatter(row.targetDate) : "N.D"}</span>
                              )}
                           </td>
                           <td>
                              {(isOe || userRole === 5) && !row.noticeTargetId ? (
                                 <Form.Control
                                    type="text"
                                    value={row.uom}
                                    onChange={(e) =>
                                       handleInputChange(row.id, "uom", e.target.value, i)
                                    }
                                 />
                              ) : (
                                 <div className="">{row?.uom ?? "N.D."}</div>
                              )}
                           </td>
                           <td className={isOe || userRole === 5 ? '' : 'text-end'}>
                              {(isOe || userRole === 5) ? (
                                 <InputNumber
                                    min={0}
                                    minFractionDigits={0}
                                    maxFractionDigits={2}
                                    onChange={(e) =>
                                       handleInputChange(row.id, "targetValue", e.value, i)
                                    }
                                    mode="decimal"
                                    locale={localLang}
                                    className="md"
                                    value={row.targetValue}
                                    disabled={!isOe && userRole !== 5}
                                 />
                              ) : (
                                 <span>
                                    {formatToDecimals(
                                       row.targetValue,
                                       row.uom === "Abitanti" ? 0 : 2,
                                       row.uom === "Abitanti" ? 0 : 2
                                    )}
                                 </span>
                              )}
                           </td>

                           <td className="text-nowrap">
                              <Button
                                 variant="link"
                                 className={`me-3 ${i === lastIndex && (isOe || userRole === 5) ? 'visible' : 'invisible'}`}
                                 style={{ width: '25px', height: '25px' }}
                                 onClick={() => addRow(rows[rows.length - 1].id + 1)}
                                 disabled={!isThisRowFilled}
                              >
                                 <IcoPlus className="second-color ico-small indicators" />
                              </Button>
                              <Button
                                 className={`${rows.length !== 1 && !row.noticeTargetId && (isOe || userRole === 5) ? 'visible' : 'invisible'}`}
                                 style={{ width: '25px', height: '25px' }}
                                 disabled={!isOe && userRole !== 5}
                                 variant="link"
                                 onClick={() => removeRow(row, i)}
                              >
                                 <IcoMinusCircled className="red-color ico-small indicators" />
                              </Button>
                           </td>
                        </tr>
                     );
                  })}
               </tbody>
            </table>

            {/* <Button
               variant='link'
               className=''
               onClick={() => addRow(rows[rows.length - 1].id + 1)}
            >
               <IcoPlus className='second-color ico-small indicators' />
            </Button> */}
            {(isOe || userRole === 5) && (
               <Row className="mt-5 mb-2 text-center">
                  <Col>
                     <Button onClick={handleSave}><OETrans code='common.save' fallback={'Salva'}/></Button>
                  </Col>
               </Row>
            )}
         </div>
      );
   };
   const onChangeInternal = (value, fieldName, setSelect, elIndex, list) => {
      let tempList = [...list];

      let tempElement = {
         ...tempList[elIndex],
         [fieldName]: value,
      };

      tempList[elIndex] = tempElement;

      //console.log('tempList', tempList);

      setSelect(tempList); //as setList
   };

   const addElement = (typeArray, setTypeArray) => {
      let tempTypeArray = [...typeArray];

      tempTypeArray.push({
         name: "",
         amount: 0,
         admissibleAmount: 0,
         approvedAmount: 0,
         isNew: true,
      });

      setTypeArray(tempTypeArray);
   };
   const removeElement = (typeArray, setTypeArray, index) => {
      let tempTypeArray = [...typeArray];

      tempTypeArray.splice(index, 1);

      setTypeArray(tempTypeArray);
   };
   const economicInternalRows = (internalExpItems, setInternalExpItems) => {
      let rows = [];

      if (internalExpItems) {
         if (internalExpItems.length === 0) {
            internalExpItems = [
               {
                  name: "",
                  amount: 0,
                  admissibleAmount: 0,
                  approvedAmount: 0,
                  position: null,
                  isNew: true,
               },
            ];
         }

         let lastIndex = internalExpItems.length - 1;

         rows = internalExpItems
            .sort((a, b) => {
               return a.position - b.position;
            })
            .map((el, i) => {
               const isThisRowFilled = el.name ? true : false;

               return (
                  <Row key={"cig-" + i} className="d-flex align-items-center mb-3">
                     {(isOe || userRole === 5) ? (
                        <>
                           <Col className="col-4">
                              <Form.Control
                                 type="text"
                                 value={el.name ?? ""}
                                 onChange={(e) =>
                                    onChangeInternal(
                                       e.target.value,
                                       "name",
                                       setInternalExpItems,
                                       i,
                                       internalExpItems
                                    )
                                 }
                                 placeholder="Voce di spesa"
                                 disabled={!isOe && userRole !== 5}
                              />
                           </Col>
                           <Col className="col-3">
                              <InputNumber
                                 min={0}
                                 minFractionDigits={2}
                                 maxFractionDigits={2}
                                 onChange={(e) =>
                                    onChangeInternal(
                                       e.value,
                                       "amount",
                                       setInternalExpItems,
                                       i,
                                       internalExpItems
                                    )
                                 }
                                 mode="decimal"
                                 locale={localLang}
                                 className="md"
                                 value={el.amount}
                                 disabled={!isOe && userRole !== 5}
                              />
                           </Col>
                           <Col className="col-1 pe-0">
                              {i === lastIndex && (isOe || userRole === 5) ? (
                                 <Button
                                    variant="link"
                                    className="me-3"
                                    onClick={() =>
                                       addElement(internalExpItems, setInternalExpItems)
                                    }
                                    disabled={!isThisRowFilled}
                                 >
                                    <IcoPlus className="second-color ico-small indicators" />
                                 </Button>
                              ) : null}
                              {(isOe || userRole === 5) && (
                                 <Button
                                    variant="link"
                                    onClick={() =>
                                       removeElement(
                                          internalExpItems,
                                          setInternalExpItems,
                                          i
                                       )
                                    }
                                 >
                                    <IcoMinusCircled className="red-color ico-small indicators" />
                                 </Button>
                              )}
                           </Col>
                        </>
                     ) : (
                        <>
                           <Col className="col-4 ps3-3">
                              <p className="f-600">{el.name}</p>
                           </Col>
                           <Col className="col-3 text-end">
                              <span className="">{formatToDecimals(el.amount)}</span>
                           </Col>
                           <Col className="col-1"></Col>
                        </>
                     )}
                  </Row>
               );
            });
      }

      return rows;
   };

   const handleSaveEconomicsInternal = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const set = new Set();

      const isNotNameUnique = internalExpenseItems.some(
         (object) => set.size === (set.add(object.name), set.size)
      );

      if (isNotNameUnique) {
         // handleStatusBar('error', 'I codici CIG non possono essere uguali', false);
         handleStatusBar(
            "error",
            "I nomi degli expense items non possono essere uguali",
            true
         );
         return;
      }

      let finalInternalExpenseItems = internalExpenseItems.map((el, i) => ({
         ...el,
         position: i,
      }));

      let resultOfActions = [true];
      let resultOfMessages = [];

      let [internalExpenseItemsResults] = await Promise.all([
         handleItemList(
            "internalExp",
            originInternalExpenseItems,
            finalInternalExpenseItems,
            deleteProjectInternalExpenseItems,
            editProjectInternalExpenseItems,
            createProjectInternalExpenseItems
         ),
      ]);

      const resultOfAll = resultOfActions.concat(
         internalExpenseItemsResults
            ? internalExpenseItemsResults.resultOfActionsTemp
            : []
      );
      const resultOfAllMessages = resultOfMessages.concat(
         internalExpenseItemsResults
            ? internalExpenseItemsResults.messageResult
            : []
      );

      if (
         resultOfAll.length === 0 ||
         (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))
      ) {
         setTimeout(() => {
            getAllProjectInternalExpenseItems(project.id).then(
               (internalExpenseItems) => {
                  // const tempTargets = targets.data.map((target) => ({
                  //   id: target.id,
                  //   name: target.name,
                  //   description: target.description,
                  //   uom: target.uom,
                  //   projectId: target.projectId,
                  //   noticeTargetId: target.noticeTargetId,
                  //   targetValue: target.targetValue,
                  //   targetDate: target.targetDate,
                  // }));

                  setInternalExpenseItems(internalExpenseItems.data);
                  setOriginInternalExpenseItems(internalExpenseItems.data);
               }
            );
         }, 1500);

         handleStatusBar("success", "Operazione eseguita con successo", true);
      } else {
         handleStatusBar(
            "error",
            resultOfAllMessages.length > 0
               ? Object.values(resultOfAllMessages[0].data).join()
               : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
      }
   };

   return (
      <>
         <div className="candidacy simple-box scenaritab">
            <Row>
               <Tabs
                  defaultActiveKey="economics"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  variant='pills'
               >
                  <Tab eventKey="economics" title="Quadro economico">
                     {economicsTab()}
                  </Tab>
                  <Tab eventKey="internalEconomics" title="Riclassificazione interna">
                     {/* {economicsInternalRow(internalExpenseItems)} */}
                     <Row className="mt-5 mb-4">
                        <Col className="col-4">
                           {/* <div className='f-600 text-end'>Nome</div>{' '} */}
                        </Col>

                        <Col className="col-3">
                           <div className="f-600 text-end">Costo progetto (€)</div>
                        </Col>
                        {/* <Col className='col'>
                  <div className='f-600 text-end'>Importo Ammissibile</div>{' '}
                </Col>
                <Col className='col'>
                  <div className='f-600 text-end'>Importo Approvato</div>{' '}
                </Col> */}
                        <Col className="col-1"></Col>
                     </Row>
                     {economicInternalRows(
                        internalExpenseItems,
                        setInternalExpenseItems
                     )}
                     <Row className="mt-3 mb-3">
                        <Col className="col-4 pt-4" style={{ borderTop: '1px solid #E6E6E6' }}>
                           <span
                              style={{ fontSize: "18px" }}
                              className="text-start h5 text-uppercase f-600"
                           >
                              Totale
                           </span>
                        </Col>

                        <Col className="col-3 pt-4" style={{ borderTop: '1px solid #E6E6E6' }}>
                           <div className="text-end">
                              {Array.isArray(internalExpenseItems) &&
                                 Object.keys(totalControls).length > 0 ? (
                                 <ConditionalWrapper
                                    condition={false} //internalExpenseItems.reduce((acc, curr) => curr.amount + acc, 0) !== totalControls?.totalAmount (da concordare)
                                    wrapper={(children) => (
                                       <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                             <Tooltip>
                                                Il valore non corrisponde a quanto presente in
                                                Dashboard:
                                                <br />
                                                {formatToDecimals(
                                                   totalControls?.totalAmount
                                                )} €
                                             </Tooltip>
                                          }
                                       >
                                          <span className="text-danger">{children}</span>
                                       </OverlayTrigger>
                                    )}
                                 >
                                    <span className="f-600" style={{ paddingRight: !isOe && userRole !== 5 ? '0' : '17px' }}>
                                       {formatToDecimals(internalExpenseItems.reduce((acc, curr) => curr.amount + acc, 0))}
                                    </span>
                                 </ConditionalWrapper>
                              ) :
                                 <Spinner animation='border' role='status' size="sm" style={{ marginTop: '0.5rem', marginRight: !isOe && userRole !== 5 ? '0' : '13px' }}>
                                   <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                                 </Spinner>}
                           </div>
                        </Col>
                        {/* <Col className='col'>
                  <div className='text-end'>
                    {`${formatToDecimals(
                      internalExpenseItems.reduce((acc, curr) => curr.admissibleAmount + acc, 0)
                    )}`}
                  </div>{' '}
                </Col>
                <Col className='col'>
                  <div className='text-end'>
                    {`${formatToDecimals(
                      internalExpenseItems.reduce((acc, curr) => curr.approvedAmount + acc, 0)
                    )}`}
                  </div>{' '}
                </Col> */}
                        <Col className="col-1"></Col>
                     </Row>
                     {(isOe || userRole === 5) && (
                        <Row className="mt-5 mb-2 text-center">
                           <Col>
                              <Button onClick={(e) => handleSaveEconomicsInternal(e)}>
                                 <OETrans code='common.save' fallback={'Salva'}/>
                              </Button>
                           </Col>
                        </Row>
                     )}
                  </Tab>
                  <Tab eventKey="target" title="Target">
                     {targetRow()}
                  </Tab>
               </Tabs>
            </Row>
         </div>
         {sbShow}
         {/*  <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'>
                  <h5 className='title-simple-box'>Documenti utili</h5>
               </div>
            </div>

            <Row className='mb-3 mt-5'>
               <Col className='col-6 border-tab right text-start py-3'>
                  <Form.Group as={Col} className='mb-4 px-3' >
                     <Form.Label className='mb-3 me-1'>
                        Progetto Candidato
                        <span className='required-field'>*</span>
                     </Form.Label>
                     <div className='me-4 d-block'>
                        <Button variant='link'>
                           <IcoUploadFile role='button' className='second-color ico-small download-upload align-bottom' />
                        </Button>
                     </div>
                  </Form.Group>

                  <Form.Group as={Col} className='mb-4 px-3' >
                     <Form.Label className='mb-3 me-1'>
                        Allegati candidatura
                        <span className='required-field'>*</span>
                     </Form.Label>
                     <div className='me-4 d-block'>
                        <Button variant='link'>
                           <IcoUploadFile role='button' className='second-color ico-small download-upload align-bottom' />
                        </Button>
                     </div>
                  </Form.Group>

                  <Form.Group as={Col} className='mb-4 px-3' >
                     <Form.Label className='mb-3 me-1'>
                        Graduatoria
                        <span className='required-field'>*</span>
                     </Form.Label>
                     <div className='me-4 d-block'>
                        <Button variant='link'>
                           <IcoUploadFile role='button' className='second-color ico-small download-upload align-bottom' />
                        </Button>
                     </div>
                  </Form.Group>

                  <Form.Group as={Col} className='mb-4 px-3' >
                     <Form.Label className='mb-3 me-1'>
                        Decreto di concessione
                        <span className='required-field'>*</span>
                     </Form.Label>
                     <div className='me-4 d-block'>
                        <Button variant='link'>
                           <IcoUploadFile role='button' className='second-color ico-small download-upload align-bottom' />
                        </Button>
                     </div>
                  </Form.Group>

                  <Row className="mt-5 px-3 ">
                     <Form.Group as={Col} className='' >
                        <Form.Label className='mb-3 me-1'>
                           Altri documenti
                        </Form.Label>
                        <Row className='me-4 align-items-center'>
                           <Select
                              as={Col}
                              className='col-10'
                              name="docs"
                              placeholder="Seleziona tipo documento..."
                              value={[]}
                              options={[]}
                              onChange={(e) => onChange(e)}
                           />
                           <Button as={Col} variant='link' className="col-2 ps-3 mt-1 text-start">
                              <IcoUploadFile role='button' className='second-color ico-small download-upload align-bottom' />
                           </Button>
                        </Row>
                     </Form.Group>
                  </Row>
               </Col>

               <Col className='col-6 py-3 text-center text-muted'>
                  viewer documenti
               </Col>
            </Row>
         </div> */}

         {/*   <Button
            variant="secondary"
            className="save-button"
            type="submit"
            form="candidacy-form"
            onClick={handleSave}
         >
            SALVA
         </Button> */}
      </>
   );
};
export default Candidacy;
