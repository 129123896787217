import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import {
   Row,
   Col,
   Spinner,
   /* OverlayTrigger,
   Tooltip,
   Button, */
   ButtonToolbar,
} from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
//import { IcoRefresh } from '@icons';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { OETrans } from 'components/translation/OETrans';

const OETableAgGrid = ({
   children,
   column,
   data,
   dataMeta,
   hideSearchBar,
   conditionForCheck,
   handleItemListSelection,
   handleItemListSelectionProps,
   sortable = false,
   flex = false,
   otherButtons = null,
   setModelFilterString,
   refTable,
}) => {
   const [rows, setRows] = useState(null);
   const [rowsMeta, setRowsMeta] = useState({});
   const [columnDefs, setColumnDefs] = useState([]);
   const [isSecondLoad, setIsSecondLoad] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const [isChangedPagination, setIsChangedPagination] = useState(false);
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;

   const getRowStyle = (params) => {
      if (params.data.number === 'TOTALE') {
         return { fontWeight: 'bold' }; // Customize the styling as per your requirements
      }
      return null; // Default style for other rows
   };

   useEffect(() => {
      setRows(data);
      setRowsMeta(dataMeta);
   }, [data, dataMeta]);

   useEffect(() => {
      setColumnDefs(column);
   }, [column]);

   const defaultColDef = {
      resizable: true,
      //wrapText: true,
      //autoHeight: true,
      flex: flex,
      suppressMovable: true,
      //suppressSizeToFit: false,
      //suppressColumnVirtualisation=true
      sortable: sortable,
      filterParams: {
         suppressAndOrCondition: true, //for disable AND/OR login in filter
         filterOptions: ['contains'],
      },
      cellStyle: {
         padding: '3px 1rem',
         textOverflow: "ellipsis",
         whiteSpace: "nowrap",
         overflow: "hidden",
      },
   };

   useEffect(() => {
      if (handleItemListSelectionProps && gridRef && gridRef.current && Object.values(dataMeta).length > 0) {
         (gridRef.current)?.api?.forEachNodeAfterFilter((node) => {
            // eslint-disable-next-line no-eval
            node.setSelected(eval('node.' + conditionForCheck));
         });

         if (isChangedPagination) {
            const funcSetData = handleItemListSelectionProps[3];
            const funcSetDataMeta = handleItemListSelectionProps[4];
            funcSetData(rows);
            funcSetDataMeta(rowsMeta);
         }
      }
    }, [conditionForCheck, dataMeta, handleItemListSelectionProps, isChangedPagination, rows, rowsMeta]);

   const handleData = useCallback(
      (params, gridRef) => {
         setRows(data);
         setRowsMeta(dataMeta);

         (params || gridRef.current).api.sizeColumnsToFit();

         window.addEventListener('resize', function () {
            setTimeout(function () {
               //params.api.sizeColumnsToFit();
               (params || gridRef.current).api.sizeColumnsToFit();
            });
         });

         if (window.screen.width >= 1600) {
            //&& window.screen.height >= 768
            // Resolution is 1600 //x768 or above
            (params || gridRef.current).api.sizeColumnsToFit();
         } else {
            const allColumnIds = [];
            (params || gridRef.current).columnApi.getAllColumns().forEach((column) => {
               allColumnIds.push(column.getId());
            });
            (params || gridRef.current).columnApi.autoSizeColumns(allColumnIds, false);
         }

         if (conditionForCheck && Object.values(dataMeta).length > 0) {
            (params || gridRef.current).api.forEachNodeAfterFilter((node) => {
               // eslint-disable-next-line no-eval
               node.setSelected(eval('node.' + conditionForCheck));
            });
         }
      },
      [conditionForCheck, data, dataMeta]
   );

   const onFilterChanged = (e) => {
      const model = e.api.getFilterModel();
      let queryParamsString = '';

      for (const [key, value] of Object.entries(model)) {
         queryParamsString += `${queryParamsString === '' ? '?' : '&'}${key}=${value.filter}`;
      }

      if (setModelFilterString) setModelFilterString(queryParamsString);
   };

   const onSelectionChanged = (e) => {
      const selectedRows = e.api.getSelectedRows();
      setIsChangedPagination(false);

      if (handleItemListSelectionProps[1].length === 0 || isSecondLoad) {
         if (isChangedPagination) {
            //eslint-disable-next-line no-eval
            const checkedNewRows = rows.filter(data => eval(conditionForCheck)); //non togliere o rinominare data
      
            handleItemListSelectionProps[1] = checkedNewRows;
            handleItemListSelection(handleItemListSelectionProps, selectedRows, isChangedPagination, setIsChangedPagination);
         } else {
            handleItemListSelection(handleItemListSelectionProps, selectedRows, isChangedPagination);
         }
      }

      setIsSecondLoad(true);
   };

   /* const reloadButton = () => {
      return (
         <OverlayTrigger placement='top' overlay={<Tooltip><OETrans code='common.reloaddata' fallback={'Ricarica i dati'}/></Tooltip>}>
            <Button onClick={() => handleData(undefined, gridRef)} style={{ padding: '0.2rem' }}>
               <IcoRefresh />
            </Button>
         </OverlayTrigger>
      );
   }; */

   const dataTable = () => {
      return (
         <Fragment>
            {hideSearchBar === true ? null : (
               <Row className='align-items-center mb-3'>
                  <Col className='col-auto'>{children}</Col>
                  <Col className='col-auto ms-auto py-1'>
                     <ButtonToolbar className='justify-content-end'>
                        {otherButtons}
                        {/* {reloadButton()} */}
                        <CustomQuickFilterBox
                           tableRef={gridRef}
                           dataMeta={rowsMeta}
                           setData={setRows}
                           dataLength={rows?.length}
                           isFirstResearch={isFirstResearch} 
                           setIsFirstResearch={setIsFirstResearch}
                           refTable={refTable}
                        />
                     </ButtonToolbar>
                  </Col>
               </Row>
            )}

            <Row>
               {Array.isArray(rows) ? (
                  <Col className='col-12 col-xxl-9' id='grid-wrapper'>
                     <div className='ag-theme-alpine list-table'>
                           <AgGridReact
                              ref={gridRef}
                              columnDefs={columnDefs}
                              rowData={rows}
                              getRowStyle={getRowStyle}
                              defaultColDef={defaultColDef}
                              domLayout='autoHeight'
                              tooltipShowDelay={0}
                              tooltipHideDelay={2000}
                              tooltipMouseTrack={true}
                              onGridReady={(e) => handleData(e, gridRef)}
                              onFirstDataRendered={(e) => handleData(e, gridRef)}
                              //onModelUpdated={(e) => handleData(e, gridRef)}
                              onFilterChanged={onFilterChanged}
                              headerHeight={50}
                              rowHeight={50}
                              ariaDescription='ag-grid-table'
                              suppressCellFocus={true}
                              rowSelection={'multiple'}
                              onSelectionChanged={onSelectionChanged}
                              suppressRowClickSelection={true}
                              rowMultiSelectWithClick={true}
                              localeText={localeText}
                              {...(handleItemListSelectionProps && { components: {customHeader: handleItemListSelectionProps[5]} })}
                           />
                           {Object.keys(rowsMeta).length > 0 && (
                              <CustomPaginationMui
                                 dataMeta={rowsMeta} 
                                 setData={setRows} 
                                 setDataMeta={setRowsMeta}
                                 refTable={refTable}
                                 setIsFirstResearch={setIsFirstResearch}
                                 setIsChangedPagination={setIsChangedPagination}
                              />
                           )}
                        </div>
                  </Col>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                       <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                     </Spinner>
                  </div>
               )}
            </Row>
         </Fragment>
      );
   };

   return (
      <>
         <div className='mt-4'>{dataTable()}</div>
      </>
   );
};

export default OETableAgGrid;
